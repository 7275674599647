export const HSRApoHisto = [
  {
    slug: 'acheron',
    score: 3358,
    usage: 37.04,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 3194,
    usage: 2.31,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 3000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 3281,
    usage: 0.47,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 3472,
    usage: 52.74,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 3325,
    usage: 0.64,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 3270,
    usage: 9.92,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 3200,
    usage: 0.65,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 3351,
    usage: 2.54,
    role: 'dps'
  },
  {
    slug: 'bronya',
    score: 3381,
    usage: 5.77,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 3139,
    usage: 1.18,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 3000,
    usage: 0.02,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 3178,
    usage: 3.64,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 3502,
    usage: 33.7,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 3439,
    usage: 55.97,
    role: 'dps'
  },
  {
    slug: 'fu-xuan',
    score: 3377,
    usage: 23.29,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 3420,
    usage: 51.04,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 3329,
    usage: 0.85,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 3332,
    usage: 1.63,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 3000,
    usage: 0.08,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 3211,
    usage: 11.62,
    role: 'specialist'
  },
  {
    slug: 'himeko',
    score: 3269,
    usage: 4,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 3000,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 3379,
    usage: 17.32,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 3209,
    usage: 3.8,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 3324,
    usage: 11.24,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 3407,
    usage: 21.47,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 3308,
    usage: 9.17,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 3097,
    usage: 0.65,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 3281,
    usage: 10.55,
    role: 'dps'
  },
  {
    slug: 'lingsha',
    score: 3514,
    usage: 37.19,
    role: 'sustain'
  },
  {
    slug: 'luka',
    score: 3000,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'luocha',
    score: 3370,
    usage: 8.78,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 3317,
    usage: 0.48,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 3000,
    usage: 0.08,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 3379,
    usage: 16.36,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 3431,
    usage: 1.84,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 3000,
    usage: 0.05,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 3372,
    usage: 16.22,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 3135,
    usage: 0.66,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 3473,
    usage: 15.44,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 3443,
    usage: 56.62,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 3444,
    usage: 82.49,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 3000,
    usage: 0.16,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 3115,
    usage: 0.87,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 3244,
    usage: 2.0,
    role: 'specialist'
  },
  {
    slug: 'silver-wolf',
    score: 3323,
    usage: 6,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 3297.33,
    usage: 19.39,
    role: 'amplifier'
  },
  {
    slug: 'sunday',
    score: 3387,
    usage: 35.15,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 3000,
    usage: 0.06,
    role: 'dps'
  },
  {
    slug: 'the-herta',
    score: 3475,
    usage: 7.75,
    role: 'dps',
    new: true
  },
  {
    slug: 'tingyun',
    score: 3278,
    usage: 4.31,
    role: 'amplifier'
  },
  {
    slug: 'tingyun-fugue',
    score: 3423,
    usage: 14.34,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'topaz',
    score: 3501,
    usage: 20.17,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-destruction',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'trailblazer-harmony',
    score: 3434,
    usage: 63.8,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-preservation',
    score: 3000,
    usage: 0.02,
    role: 'sustain'
  },
  {
    slug: 'trailblazer-remembrance',
    score: 3517,
    usage: 7.75,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'welt',
    score: 3264.5,
    usage: 0.87,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 3104,
    usage: 0.28,
    role: 'dps'
  },
  {
    slug: 'yanqing',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 3000,
    usage: 0.04,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 3270,
    usage: 5.25,
    role: 'dps'
  }
];
