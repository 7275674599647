export const ApoCurrentStage4Side1 = [
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 4.76,
    rank: 1,
    avg_round: 3560,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 4.45,
    rank: 2,
    avg_round: 3451,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 3.37,
    rank: 3,
    avg_round: 3491,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 3.06,
    rank: 4,
    avg_round: 3570,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.94,
    rank: 5,
    avg_round: 3259,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 2.08,
    rank: 6,
    avg_round: 3552,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 1.96,
    rank: 7,
    avg_round: 3505,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.89,
    rank: 8,
    avg_round: 3577,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 1.79,
    rank: 9,
    avg_round: 3377,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 1.42,
    rank: 10,
    avg_round: 3571,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 1.38,
    rank: 11,
    avg_round: 3572,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 1.37,
    rank: 12,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 1.36,
    rank: 13,
    avg_round: 3423,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 1.32,
    rank: 14,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.3,
    rank: 15,
    avg_round: 3501,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.29,
    rank: 16,
    avg_round: 3467,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 1.22,
    rank: 17,
    avg_round: 3527,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 1.2,
    rank: 18,
    avg_round: 3611,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 1.2,
    rank: 18,
    avg_round: 3542,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.18,
    rank: 20,
    avg_round: 3122,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.08,
    rank: 21,
    avg_round: 3280,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.03,
    rank: 22,
    avg_round: 3418,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 1.02,
    rank: 23,
    avg_round: 3463,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.98,
    rank: 24,
    avg_round: 3113,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.93,
    rank: 25,
    avg_round: 3148,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.91,
    rank: 26,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.84,
    rank: 27,
    avg_round: 3583,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.8,
    rank: 28,
    avg_round: 3612,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.8,
    rank: 28,
    avg_round: 3526,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.74,
    rank: 30,
    avg_round: 3460,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.73,
    rank: 31,
    avg_round: 3347,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.68,
    rank: 32,
    avg_round: 3276,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.67,
    rank: 33,
    avg_round: 3300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.66,
    rank: 34,
    avg_round: 3541,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.64,
    rank: 35,
    avg_round: 3381,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.64,
    rank: 35,
    avg_round: 3290,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.64,
    rank: 35,
    avg_round: 3518,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.62,
    rank: 38,
    avg_round: 3355,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.62,
    rank: 38,
    avg_round: 3505,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.61,
    rank: 40,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.59,
    rank: 41,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.56,
    rank: 42,
    avg_round: 3509,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.55,
    rank: 43,
    avg_round: 3486,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.53,
    rank: 44,
    avg_round: 3529,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.52,
    rank: 45,
    avg_round: 3475,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 46,
    avg_round: 3366,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.48,
    rank: 47,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.45,
    rank: 48,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.43,
    rank: 49,
    avg_round: 3469,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.41,
    rank: 50,
    avg_round: 3517,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 51,
    avg_round: 3256,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.38,
    rank: 52,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.38,
    rank: 52,
    avg_round: 3537,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.37,
    rank: 54,
    avg_round: 3470,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.36,
    rank: 55,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.36,
    rank: 55,
    avg_round: 3286,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.35,
    rank: 57,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 58,
    avg_round: 3452,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 59,
    avg_round: 3511,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 59,
    avg_round: 3325,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.31,
    rank: 61,
    avg_round: 3558,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 61,
    avg_round: 3226,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.3,
    rank: 63,
    avg_round: 3453,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.3,
    rank: 63,
    avg_round: 3534,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.29,
    rank: 65,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.29,
    rank: 65,
    avg_round: 3299,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.29,
    rank: 65,
    avg_round: 3291,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 65,
    avg_round: 3365,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 65,
    avg_round: 3340,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.28,
    rank: 70,
    avg_round: 3229,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 71,
    avg_round: 3167,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.27,
    rank: 71,
    avg_round: 3483,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.27,
    rank: 71,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 71,
    avg_round: 3523,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.27,
    rank: 71,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.26,
    rank: 76,
    avg_round: 3150,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.26,
    rank: 76,
    avg_round: 3364,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.26,
    rank: 76,
    avg_round: 3365,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.26,
    rank: 76,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.25,
    rank: 80,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 80,
    avg_round: 3566,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.23,
    rank: 82,
    avg_round: 3463,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 82,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.23,
    rank: 82,
    avg_round: 3781,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 82,
    avg_round: 3134,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 86,
    avg_round: 3529,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 86,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 86,
    avg_round: 3330,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3431,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3481,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3391,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 89,
    avg_round: 3327,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 95,
    avg_round: 3242,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.2,
    rank: 95,
    avg_round: 3422,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 95,
    avg_round: 3498,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.19,
    rank: 98,
    avg_round: 3249,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 99,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 99,
    avg_round: 3707,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 99,
    avg_round: 3483,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3733,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3410,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3612,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3389,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 102,
    avg_round: 3554,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 102,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3680,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3376,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3758,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3305,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 109,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 109,
    avg_round: 3470,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3453,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3260,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3300,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 116,
    avg_round: 3318,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 122,
    avg_round: 3151,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3157,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3710,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3247,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 123,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3243,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 123,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 131,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3394,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3414,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3251,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3798,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3543,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3250,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3547,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3386,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3462,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 140,
    avg_round: 3323,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3169,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3119,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3433,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 148,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 148,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3230,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3523,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3128,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3279,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3068,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.09,
    rank: 148,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3117,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 148,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3221,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3603,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 164,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 164,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3301,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3264,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3264,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3123,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3505,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 164,
    avg_round: 3319,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3253,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3265,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3298,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3262,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3302,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3776,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3485,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3186,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3375,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3474,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 179,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 179,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3745,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3133,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3249,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3122,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3195,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3169,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3297,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3462,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3394,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3554,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3784,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3005,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3323,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 200,
    avg_round: 3264,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 200,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3572,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3464,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3210,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3056,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3463,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3257,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3757,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tingyun',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3796,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3135,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3388,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3323,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3302,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3222,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3067,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 224,
    avg_round: 3491,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3284,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3180,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'march-7th',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3612,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3353,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3139,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3365,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3216,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3168,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3269,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3438,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3135,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3284,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3265,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3161,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3238,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3389,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3139,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 259,
    avg_round: 3419,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 259,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3754,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3200,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3215,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3528,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3189,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3359,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3132,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3417,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3239,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3107,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3353,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3169,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3270,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3343,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3238,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3236,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3126,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3033,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3618,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3547,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3266,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3226,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3250,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3188,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3346,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3252,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3104,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'argenti',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3376,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3174,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3352,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3251,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3490,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3207,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3171,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3107,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 303,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3425,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'luocha',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3123,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3249,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3427,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3118,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3178,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 2988,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'serval',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3456,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3148,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3412,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'qingque',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3189,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'yunli',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3283,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3647,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3315,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3386,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3751,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3180,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3269,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3466,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3055,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3093,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3108,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3004,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3359,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3537,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'yunli',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3719,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3349,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3108,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3211,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3203,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3419,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3814,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3280,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3197,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3315,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3200,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3262,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3471,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3168,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3089,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3079,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3197,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3557,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3112,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3194,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3218,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3252,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3117,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 369,
    avg_round: 3283,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  }
];
